import { useMemo } from 'react';
import {
  Datagrid,
  Record,
  TextField,
  BooleanField,
  Loading,
  useListContext
} from 'react-admin';

import { EditMarketplacePublicButton } from 'resources/contents/EditMarketplacePublicButton.component';
import { EditNewTagButton } from 'resources/contents/EditNewTagButton.component';
import { EditCommunityButton } from 'resources/contents/EditOverrideBenchmarkButton.component';
import { EditTrendingTagButton } from 'resources/contents/EditTrendingTagButton.component';

import { FieldObject } from 'resources/types';
import { BPErrorView } from 'shared/components/common/BPErrorView.component';
import { LinkableField } from 'shared/components/fields/LinkableField.component';
import { useAppPermissions } from 'shared/hooks/useAppPermissions';
import { HasOmitItems } from 'shared/interfaces/react-admin';
import { isPublisher } from 'shared/services/firebase-auth-provider/checkPermissions';
import { shortenString } from 'shared/utils/helperFunctions/shortenString';

import { ContentItem } from '../models/ContentItem.model';
import { EditChallengeButton } from 'resources/contents/EditChallengeButton.component';

export const ActivitiesTable = (
  props: Partial<HasOmitItems<keyof ContentItem>>
) => {
  const { permissions } = useAppPermissions();
  const listContext = useListContext<ContentItem>();
  const fields = useMemo(() => {
    const allFields: Array<FieldObject<keyof ContentItem>> = [
      {
        source: 'display_name',
        element: (
          <LinkableField
            source={'display_name'}
            label='Name'
            render={(record?: Record) =>
              shortenString(record?.display_name ?? '', 50)
            }
            emptyText='-'
          />
        )
      },
      {
        source: 'visibility',
        element: (
          <TextField
            sortable={false}
            source='visibility'
            label='App visibility'
            emptyText='-'
          />
        )
      },
      {
        source: 'is_popular',
        element: (
          <BooleanField
            sortable={false}
            source='is_popular'
            label='Is Popular'
            emptyText='-'
          />
        )
      },
      {
        source: 'has_benchmark',
        element: (
          <BooleanField
            sortable={false}
            source='has_benchmark'
            label='Benchmark'
            emptyText='-'
          />
        )
      }
    ];

    const output: JSX.Element[] = [];

    for (const field of allFields) {
      if (props.omitItems ? !props.omitItems.includes(field.source) : true) {
        output.push(field.element);
      }
    }
    return output;
  }, [props.omitItems]);

  return (
    <>
      {listContext.loading ? (
        <Loading />
      ) : listContext.error ? (
        <BPErrorView error={listContext.error} />
      ) : (
        <Datagrid>
          {fields}
          {isPublisher(permissions?.content) && <EditNewTagButton />}
          {isPublisher(permissions?.content) && <EditTrendingTagButton />}
          {isPublisher(permissions?.content) && <EditCommunityButton />}
          {isPublisher(permissions?.content) && <EditChallengeButton />}
        </Datagrid>
      )}
    </>
  );
};
